export function greaterThan(a: string, b: string){
    const aDisected = a.split(".");
    const bDisected = b.split(".");

    for(let i = 0; i < aDisected.length; i++){
        if(aDisected[i] === bDisected[i]){
            continue;
        }
        if(aDisected[i] > bDisected[i]){
            return true;
        }else{
            return false;
        }
    }

    return false;
}
export function equalOrGreater(a: string, b: string){
    if(a === b){
        return true;
    }

    return greaterThan(a, b);
}