import { IPackage, IPackageIdentity, IPackageVersion, PackageStatus } from "../../typings/Packaging";

export interface IValidationStatus {
    error: string[];
    add: IPackage[];
    versions: IPackageVersion[];
}

export function validateImport(updates: IPackage[], identities: IPackageIdentity[], ignoreFailedMatch: boolean = false): IValidationStatus {
    const status: IValidationStatus = {
        error: [],
        add: [],
        versions: []
    };

    // add new solutions
    for (let i = 0; i < identities.length; i++) {
        let index = updates.findIndex(x => x.id === identities[i].id);
        if (index === -1 || updates[index].status === PackageStatus.New) {
            const versiontoAdd: IPackage = {
                id: identities[i].id,
                friendlyId: identities[i].friendlyId,
                currentVersionNumber: identities[i].version,
                versions: []
            };
            status.add.push(versiontoAdd);
        }
    }

    // import file does not contain installed solution
    for (let i = 0; i < updates.length; i++) {
        let packageUpdate = updates[i];
        let identity = identities.find(x => x.id === packageUpdate.id);

        if (!identity) {
            if (!ignoreFailedMatch) {
                status.error.push('Installed solution ' + packageUpdate.id + ' not found in import file.');
            }
            continue;
        }

        // new solution or same version, no need to update selection
        if (packageUpdate.status === PackageStatus.New || packageUpdate.currentVersionNumber === identity.version) {
            continue;
        }

        // installed version must be smaller than currently installed
        if (packageUpdate.currentVersionNumber > identity.version) {
            if (!ignoreFailedMatch) {
                status.error.push(`Can't select update for ${packageUpdate.id}. Installed version (${packageUpdate.currentVersionNumber}) is higher than import version (${identity.version})`);
            }
            continue;
        }

        let version = packageUpdate.versions.find(x => x.version === identity?.version);

        // updated version not found in available package updates
        if (!version) {
            if (!ignoreFailedMatch) {
                status.error.push(`Can't select update for ${identity?.id}. Version (${identity?.version}) not found in available updates.`);
            }
            continue;
        }

        status.versions.push(version);
    }

    return status;
}